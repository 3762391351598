define(['app', '$window', 'accessibleModalHelper'], (app, $window, accessibleModalHelper) => () => {
  const TRANSITION_TIME = 0.6; // in seconds
  const unfixBody = () => {
    if (document.body.style.position === 'fixed') {
      const prevScrollTop = -1 * parseFloat(document.body.style.top);
      document.body.style.position = '';
      document.body.style.top = '';
      document.body.style.width = '';
      document.body.style.height = '';
      document.body.style.overscrollBehavior = '';
      window.scrollTo(0, prevScrollTop);
    }
  };

  const fixBody = () => {
    document.body.style.top = `-${window.scrollY}px`;
    document.body.style.position = 'fixed';
    document.body.style.width = window.outerWidth;
    document.body.style.height = window.outerHeight;
    document.body.style.overscrollBehavior = 'contain';
  };

  const _selectors = {
    image: '.productImageZoom_image',
    imagePreview: '.productImageZoom_imagePreview',
    imageWrapper: '.productImageZoom_imageWrapper',
    loader: '.productImageZoom_loader',
    thumbnailContainer: '.productImageZoom_thumbnailContainer',
    overlay: '.productImageZoom_overlay',
    exit: '.productImageZoom_exit',
    zoomIn: '.productImageZoom_icon.zoomIn',
    zoomOut: '.productImageZoom_icon.zoomOut',
    hiddenTitle: '#product-image-zoom-modal-title',
    isTransparent: 'data-is-transparent',
  };

  const _classes = {
    thumbnail: 'productImageZoom_thumbnail',
    thumbnailButton: 'productImageZoom_thumbnailButton',
    thumbnailWrapper: 'productImageZoom_thumbnailWrapper',
  };

  const _attr = {
    hide: 'data-hide',
    disabled: 'data-disabled',
    active: 'data-active',
  };

  const _zoom = {
    levels: [1, 1.7],
    levelIndex: 1,
    get level() {
      const {levels, levelIndex} = _zoom;
      return levels[levelIndex];
    },
    get isMaxLevel() {
      const {levels, levelIndex} = _zoom;
      return levelIndex === levels.length - 1;
    },
    get isMinLevel() {
      const {levelIndex} = _zoom;
      return levelIndex === 0;
    },
    startX: 0,
    startY: 0,
    imageX: 0,
    imageY: 0,
  };

  const comp = {
    element: null,
    image: null,
    imageWrapper: null,
    thumbnailContainer: null,
    selection: 0,
    exit: null,
    images: [],
    zoomInEl: null,
    zoomOutEl: null,
    // testing
    _zoom,
    _classes,
    _attr,
  };

  /**
   * Initialises the component
   *
   * @param {HTMLElement} element
   */
  comp.init = function (element) {
    comp.element = element;
    comp.image = element.querySelector(_selectors.image);
    comp.imagePreview = element.querySelector(_selectors.imagePreview);
    comp.imageWrapper = element.querySelector(_selectors.imageWrapper);
    comp.loader = element.querySelector(_selectors.loader);
    comp.thumbnailContainer = element.querySelector(_selectors.thumbnailContainer);
    comp.overlay = element.querySelector(_selectors.overlay);
    comp.exit = element.querySelector(_selectors.exit);
    comp.zoomInEl = element.querySelector(_selectors.zoomIn);
    comp.zoomOutEl = element.querySelector(_selectors.zoomOut);
    comp.hiddenTitle = element.querySelector(_selectors.hiddenTitle);
    comp.isTransparent = element.getAttribute(_selectors.isTransparent);

    comp.bind();
  };

  /**
   * Adds listeners to dom elements and control tower
   */
  comp.bind = function () {
    comp.overlay.addEventListener('click', comp.close);
    comp.exit.addEventListener('click', comp.close);
    comp.image.addEventListener('load', comp.imageLoad);
    comp.imageWrapper.addEventListener('touchstart', comp.touchstart, {passive: true});
    comp.imageWrapper.addEventListener('mousedown', comp.mousedown, {passive: true});
    comp.zoomInEl.addEventListener('click', comp.zoomIn);
    comp.zoomOutEl.addEventListener('click', comp.zoomOut);

    app.subscribe('productImageZoom/open', comp.open);
  };

  /**
   * Populate with images and displays the component.
   *
   * @param {Array<{thumbnail, srcset, src}>} images array of objects, each containing 'thumbnail', 'srcset'
   * @param {Number} index image to be displayed first
   */
  comp.open = function (images, index = 0) {
    if(!comp.isTransparent) {
      fixBody();
    }
    comp.images = images;
    comp.thumbnailContainer.innerHTML = '';

    comp.images.map((image, index) => {
      const wrapper = document.createElement('li');
      const thumbnailButton = document.createElement('button');
      const thumbnailImage = document.createElement('img');

      wrapper.className = _classes.thumbnailWrapper;
      thumbnailButton.className = _classes.thumbnailButton;
      thumbnailButton.type = 'button';
      thumbnailButton._index = index;
      thumbnailImage.src = image.thumbnail;
      thumbnailImage.alt = `Open image ${index + 1} - ${image.alt}`;
      thumbnailImage.className = _classes.thumbnail;

      thumbnailButton.appendChild(thumbnailImage);

      wrapper.appendChild(thumbnailButton);
      comp.thumbnailContainer.appendChild(wrapper);
      thumbnailButton.addEventListener('click', comp.thumbnailClick);

      image.loaded = false;
      image.thumbnailEl = wrapper;
    });

    comp.navigate(index);
    comp.element.setAttribute(_attr.hide, 'false');
    comp.accessibleModalHelper = new accessibleModalHelper(comp.element, comp.close, comp.hiddenTitle);

  };

  /**
   * Hides the component
   */
  comp.close = function () {
    unfixBody();
    comp.element.setAttribute(_attr.hide, 'true');
    comp.accessibleModalHelper && comp.accessibleModalHelper.close();
    app.publish('tracking/record', 'productImageZoom', 'close', 'zoom');
  };

  /**
   * Navigates to specified image. Doesn't do anything if image doesn't exist.
   * @param {Number} index
   */
  comp.navigate = function (index) {
    if (!comp.images[index]) {
      return;
    }

    if (!comp.images[index].loaded) {
      comp.loader.style.display = 'block';
      comp.imagePreview.setAttribute(_attr.hide, 'false');
      comp.image.setAttribute(_attr.hide, 'true');
      comp.imagePreview.src = comp.images[index].thumbnail;
      comp.imagePreview.style.width = _zoom.level * 100 + '%';
      comp.imagePreview.style.transform = `scale(${_zoom.level}) translate(0, 0)`;
    }

    comp.images[comp.selection].thumbnailEl.setAttribute(_attr.active, 'false');
    comp.images[index].thumbnailEl.setAttribute(_attr.active, 'true');

    comp.selection = index;
    comp.image.style.height = '0px'; // initiating for IE
    comp.image.src = '';
    comp.image.srcset = '';
    comp.image.removeAttribute('width');
    comp.image.removeAttribute('height');
    comp.zoomTo(_zoom.levelIndex);

    $window.setImmediate
      ? $window.setImmediate(() => {comp.updateImage(index)})
      : $window.setTimeout(() => {comp.updateImage(index)}, 0);
  };

  comp.updateImage = function(index) {
    comp.image.src = comp.images[index].src;
    comp.image.srcset = comp.images[index].srcset;
    comp.image.alt = comp.images[index].alt;
    comp.image.removeAttribute('width');
    comp.image.removeAttribute('height');
  }

  /**
   * Listener for image load. Removes the background of the wrapper
   */
  comp.imageLoad = function () {
    comp.loader.style.display = 'none';
    comp.images[comp.selection].loaded = true;
    comp.image.style.height = 'auto';
    comp.imagePreview.setAttribute(_attr.hide, 'true');
    comp.image.setAttribute(_attr.hide, 'false');

    _zoom.imageX = 0;
    _zoom.imageY = 0;

    app.publish('accessibility/announce', 'polite', `Showing image ${comp.selection + 1} - ${comp.images[comp.selection].alt}`);
  };

  /**
   * Listener for thumbnail click. Relies on the target element to have '_index' property.
   *
   * @param {MouseEvent} ev
   */
  comp.thumbnailClick = function (ev) {
    const index = ev.currentTarget._index;
    comp.navigate(index);
    app.publish('tracking/record', 'productImageZoom', 'click', 'thumbnail', ev.currentTarget._index);
  };

  /**
   * Listener for touchstart event.
   *
   * @param {TouchEvent} ev
   */
  comp.touchstart = function (ev) {
    const event = ev.changedTouches[0];

    _zoom.startX = event.pageX - $window.document.documentElement.scrollLeft;
    _zoom.startY = event.pageY - $window.document.documentElement.scrollTop;

    $window.document.addEventListener('touchmove', comp.touchmove, {passive: true});
    $window.document.addEventListener('touchend', comp.touchend, {passive: true});
  };

  /**
   * Listener for touchmove event.
   *
   * @param {TouchEvent} ev
   */
  comp.touchmove = function (ev) {
    const event = ev.changedTouches[0];
    const x = event.pageX - $window.document.documentElement.scrollLeft;
    const y = event.pageY - $window.document.documentElement.scrollTop;

    comp.move(x, y, false);
  };

  /**
   * Listener for touchend event.
   *
   * @param {TouchEvent} ev
   */
  comp.touchend = function (ev) {
    const event = ev.changedTouches[0];
    const x = event.pageX - $window.document.documentElement.scrollLeft;
    const y = event.pageY - $window.document.documentElement.scrollTop;

    comp.move(x, y, true);

    $window.document.removeEventListener('touchmove', comp.touchmove);
    $window.document.removeEventListener('touchend', comp.touchend);
  };

  /**
   * Listener for mousedown event.
   *
   * @param {MouseEvent} ev
   */
  comp.mousedown = function (ev) {
    if (ev.target !== ev.currentTarget) {
      return;
    }

    _zoom.startX = ev.clientX;
    _zoom.startY = ev.clientY;

    $window.document.addEventListener('mousemove', comp.mousemove, {passive: true});
    $window.document.addEventListener('mouseup', comp.mouseup, {passive: true});
  };

  /**
   * Listener for mousemove event.
   *
   * @param {MouseEvent} ev
   */
  comp.mousemove = function (ev) {
    comp.move(ev.clientX, ev.clientY, false);
  };

  /**
   * Listener for mouseup event.
   *
   * @param {MouseEvent} ev
   */
  comp.mouseup = function (ev) {
    comp.move(ev.clientX, ev.clientY, true);

    $window.document.removeEventListener('mousemove', comp.mousemove);
    $window.document.removeEventListener('mouseup', comp.mouseup);
  };

  /**
   * Listener for mousemove and touchmove.
   *
   * @param {Number} absX
   * @param {Number} absY
   * @param {Boolean} end save newly calculated image offset
   */
  comp.move = function (absX, absY, end) {
    const {width: wrapperWidth, height: wrapperHeight} = comp.imageWrapper.getBoundingClientRect();
    const {width: imageWidth, height: imageHeight} = comp.image.getBoundingClientRect();

    const range = 100 / _zoom.level;
    const maxWidth = 50 * (imageWidth - wrapperWidth) / imageWidth;
    const maxHeight = 50 * (imageHeight - wrapperHeight) / imageHeight;

    let x = ((_zoom.startX - absX) / wrapperWidth) * range;
    let y = ((_zoom.startY - absY) / wrapperHeight) * range;

    if (imageWidth >= wrapperWidth) {
      x = Math.max(maxWidth * -1, Math.min(maxWidth, _zoom.imageX - x));
    } else {
      x = 0;
    }

    if (imageHeight >= wrapperHeight) {
      y = Math.max(maxHeight * -1, Math.min(maxHeight, _zoom.imageY - y));
    } else {
      y = 0;
    }

    if (end) {
      _zoom.imageX = x;
      _zoom.imageY = y;
      _zoom.startX = 0;
      _zoom.startY = 0;
      _zoom.active = false;
    }

    comp.image.style.transform = `scale(${_zoom.level}) translate(${x}%, ${y}%)`;
  };

  comp.zoomTo = (toLevel) => {
    _zoom.levelIndex = toLevel;

    comp.zoomInEl.setAttribute(_attr.disabled, _zoom.isMaxLevel ? 'true' : 'false');
    comp.zoomInEl.disabled = _zoom.isMaxLevel;
    comp.zoomOutEl.setAttribute(_attr.disabled, _zoom.isMinLevel ? 'true' : 'false');
    comp.zoomOutEl.disabled = _zoom.isMinLevel;

    comp.accessibleModalHelper && comp.accessibleModalHelper.setUpFocusVariables();

    comp.image.style.transition = `transform ${TRANSITION_TIME}s ease`;
    // set image zoom level before moving the image
    comp.image.style.transform = `scale(${_zoom.level})`;
    $window.setImmediate
      ? $window.setImmediate(() => comp.move(0, 0, false))
      : $window.setTimeout(() => comp.move(0, 0, false), 0);
    setTimeout(() => comp.image.style.transition = '', TRANSITION_TIME * 1000);
  };

  comp.zoomIn = function () {
    if (_zoom.isMaxLevel) return;
    comp.zoomTo(_zoom.levelIndex + 1);
  };

  comp.zoomOut = function () {
    if (_zoom.isMinLevel) return;
    comp.zoomTo(_zoom.levelIndex - 1);
  };

  return comp;
});
